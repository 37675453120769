<template>
  <div>
    <div class="NavBar">
      <router-link to="/"
        ><img class="logo" :src="site.logo" alt=""
      /></router-link>
      <div class="phone" v-if="isPhone">
        <i class="el-icon-menu" @click="drawer = true"></i>
      </div>
      <div class="right" v-if="!isPhone">
        <div class="menu">
          <div class="col" v-for="(item, index) in site.menu" :key="index">
            <div class="pointer" v-if="item.route == '/product'" on-mouseover="is_show == true">
              <span>{{item.title}}</span>
            </div>
            <ul class="cate" v-if="item.route == '/product' && is_show">
              <li v-for="(item,index) in category_list">
                <div class="cate_name">
                <router-link :to="item.url" @click.native="linkClicked">
                  {{item.title}}
                </router-link>
                </div>
                <div class="cate_name2" v-for="(ite,index2) in item.childs">
                  <router-link :to="ite.url" @click.native="linkClicked">
                  {{ite.title}}
                </router-link>
                </div>
              </li>
            </ul>
            <div class="pointer" v-if="item.route == '/styles'">
              <span> {{ item.name }}</span>
              <ul v-if="is_show">
                <li v-for="(item,index) in style_list" class="menuChild">
                  <router-link :to="item.url" @click.native="linkClicked">
                    {{item.title}}
                  </router-link>
                </li>
              </ul>
            </div>
            <router-link :to="item.route" v-else-if="item.route != 'false' && item.route != '/product' && item.route != '/styles'">
              <span> {{ item.name }}</span>
            </router-link>
            
            <div class="pointer" v-else>
              <span> {{ item.name }}</span>
              <ul v-if="is_show">
                <li v-for="menu in item.child" :key="menu.id + menu.name" class="menuChild">
                  <router-link :to="menu.route" v-if="menu.route != '/join_us'"  @click.native="linkClicked">
                    {{ menu.name }}
                  </router-link>
                  <div class="pointer" @click="join = true;" v-else>
                    {{ menu.name }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="search">
          <el-input
            v-model="value"
            placeholder="请输入关键字搜索产品"
          ></el-input>
          <i class="el-icon-search pointer" @click="search"></i>
        </div>
        <div class="icons" v-if="isLogin">
          <router-link to="/message">
            <el-badge :value="unRead" class="item" :hidden="unRead == 0">
              <i class="el-icon-bell"></i>
            </el-badge>
          </router-link>
          <router-link to="/collection">
            <i class="el-icon-star-off"></i>
          </router-link>
          <div class="pointer">
            <i class="el-icon-user"></i>
            <ul>
              <li class="pointer" @click="edit = true">
                <div>修改密码</div>
              </li>
              <li><router-link to="/user">个人中心</router-link></li>
              <li><router-link to="/order">我的订单</router-link></li>
              <li class="pointer" @click="logout">
                <div>账号退出</div>
              </li>
            </ul>
          </div>
        </div>
        <div class="login pointer" v-else @click="login = true">登录</div>
      </div>
    </div>
    <div class="seat"></div>
    <login
      :visible.sync="login"
      :isLogin.sync="isLogin"
      @handleSignup="handleSignup"
      @handleFind="handleFind"
    ></login>
    <signup
      :visible.sync="signup"
      :isLogin.sync="isLogin"
      @handleLogin="handleLogin"
      @handleFind="handleFind"
    ></signup>
    <find
      :visible.sync="find"
      @handleLogin="handleLogin"
      @handleSignup="handleSignup"
    ></find>
    <editPswd :visible.sync="edit"></editPswd>
    <join :visible.sync="join"></join>
    <!-- <drawer :visible.sync="drawer"></drawer> -->
    <el-drawer :visible.sync="drawer" :with-header="false">
      <drawer></drawer>
      <div class="mobile">
        <div class="search">
          <el-input
            v-model="value"
            placeholder="请输入关键字搜索产品"
          ></el-input>
          <i class="el-icon-search pointer" @click="search"></i>
        </div>
        <div class="icons" v-if="isLogin">
          <router-link to="/message">
            <el-badge :value="unRead" class="item" :hidden="unRead == 0">
              <i class="el-icon-bell"></i>
            </el-badge>
          </router-link>
          <router-link to="/collection">
            <i class="el-icon-star-off"></i>
          </router-link>
          <div class="pointer">
            <i class="el-icon-user"></i>
            <ul>
              <li class="pointer" @click="edit = true">
                <div>修改密码</div>
              </li>
              <li><router-link to="/user">个人中心</router-link></li>
              <li><router-link to="/order">我的订单</router-link></li>
              <li class="pointer" @click="logout">
                <div>账号退出</div>
              </li>
            </ul>
          </div>
        </div>
        <div class="login pointer" v-else @click="login = true">登录</div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "NavBar",
  data() {
    return {
      value: "",
      isLogin: false,
      login: false,
      signup: false,
      find: false,
      join: false,
      edit: false,
      isPhone: false,
      drawer: false,
      style_list:[],
      category_list:[],
      is_show:true,
    };
  },
  components: {
    login: () => import("@/components/popup/login.vue"),
    signup: () => import("@/components/popup/signup.vue"),
    find: () => import("@/components/popup/find.vue"),
    join: () => import("@/components/popup/joinus.vue"),
    editPswd: () => import("@/components/popup/editPswd.vue"),
    drawer: () => import("@/components/popup/drawer.vue"),
  },
  computed: {
    ...mapState(["site", "unRead"]),
  },
  watch: {
    $route(newVal) {
      if (newVal) {
        this.drawer = false;
      }
    },
  },
  mounted() {
    const token = localStorage.getItem("token");
    if (token) {
      this.isLogin = true;
      this.getUnread();
    } else {
      this.isLogin = false;
    }
    this.getSite();
    this.getCondition();
    const width = window.innerWidth;
    if (width < 1280) {
      this.isPhone = true;
    }
  },
  methods: {
    ...mapMutations(["getSite", "getUnRead"]),
    handleSignup() {
      this.signup = true;
    },
    handleLogin() {
      this.login = true;
    },
    handleFind() {
      this.find = true;
    },
    search() {
      this.$router.push({
        path: "/product",
        query: {
          keyword: this.value,
        },
      });
    },
    logout() {
      this.$confirm("是否要退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          localStorage.clear();
          this.isLogin = false;
          this.$router.push("/");
          this.$message({
            type: "success",
            message: "退出成功!",
          });
        })
        .catch(() => {});
    },
    getSite() {
      this.$post("/api/index/get_site").then((res) => {
        this.$store.commit("getSite", res.data);
      });
    },
    getUnread() {
      this.$post("/api/user/get_unread_number").then((res) => {
        this.$store.commit("getUnRead", res.data);
      });
    },
    getCondition() {
      this.$post("/api/index/get_product_condition").then((res) => {
        this.style_list = res.data.styles;
        this.category_list = res.data.categories;
      });
    },
    linkClicked(event) {
      // console.log('Link clicked', event.target.href);
      this.is_show = false
      let that = this
      setTimeout(function() {
        that.is_show = true
        }, 200)
    }
  },
};
</script>

<style scoped lang="scss">
.seat {
  height: 120px;
}
::v-deep .NavBar {
  position: fixed;
  left: 0;
  top: 0;
  // width: calc(100vw - 17px);
  width: 100%;
  height: 120px;
  color: #fff;
  background: #000;
  padding: 0 3%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
  .el-badge__content {
    border: none;
    background-color: #ff0000;
  }
  .el-input__inner {
    border: none;
    background-color: transparent;
    color: #fff;
  }
  .logo {
    // width: 220px;
    height: 100px;
  }
}

.right {
  display: flex;
  align-items: center;
  .menu {
    display: flex;
    align-items: center;
    margin-right: 40px;
    .col > a,
    .pointer {
      position: relative;

      span {
        display: block;
        padding: 0 20px;
        font-size: 16px;
        // font-weight: bold;
      }
    }

    ul {
      position: absolute;
      // background: rgba($color: #000, $alpha: 0.7);
      border: 1px solid #ccc;
      background: #fff;
      width: 100%;
      text-align: center;
      font-size: 16px;
      width: 150px;
      left: 50%;
      border-radius: 4px;
      overflow: hidden;
      top: 100%;
      transform: translateX(-50%) translateY(20px);
      transition: all 0.3s;
      opacity: 0;
      visibility: hidden;
      a,
      div {
        display: block;
        padding: 15px 0;
        color: #2a2a2a;
        // &:hover {
        //   background: #2a2a2a;
        // }
      }
      .menuChild:hover{
        // background: #2a2a2a;
        color: #000;
        font-weight: 800;
      }
    }
    .cate {
      z-index: 99999;
      display: flex;
      // position: absolute;
      margin-top: -50px;
      padding: 80px 42px;
      // background: rgba($color: #000, $alpha: 0.7)!important;
      background: #fff;
      width: 100%;
      justify-content: center; /* 水平居中 */
      list-style-type: none; /* 移除列表的标记 */
      padding: 0; /* 移除默认的内边距 */
      padding-bottom: 50px;
      // border-bottom: 1px solid #6b532e;
      border-radius: 4px;
      overflow: hidden;
      transform: translateX(-50%) translateY(20px);
      transition: all 0.3s;
      opacity: 0;
      visibility: hidden;
      li{
        width: 150px;
        .cate_name{
          text-align: left;
          font-size: 18px;
          height: 36px;
          color: #2a2a2a;
          &:hover {
            color: #000;
            font-weight: 800;
          }
        }
        .cate_name2{
          text-align: left;
          font-size: 16px;
          height: 36px;
          color: #2a2a2a;
          &:hover {
            color: #000;
            font-weight: 800;
          }
        }
      }

    }
    div:hover ul {
      opacity: 1;
      visibility: visible;
    }
  }
  .search {
    display: flex;
    align-items: center;
    i {
      font-size: 24px;
      color: #969696;
    }
  }
  .icons {
    display: flex;
    align-items: center;
    margin-left: 30px;
    & > a {
      margin-right: 30px;
      display: block;
    }
    i {
      font-size: 30px;
      display: block;
      // padding: 0 15px;
    }
    div {
      position: relative;
    }
    ul {
      position: absolute;
      background: #fff;
      width: 100px;
      box-shadow: 0px 0px 13px 0px rgba(171, 171, 171, 0.22);
      text-align: center;
      left: 50%;
      border-radius: 4px;
      overflow: hidden;
      top: 100%;
      transform: translateX(-50%) translateY(20px);
      transition: all 0.3s;
      opacity: 0;
      visibility: hidden;
      color: #000;
      a,
      div {
        display: block;
        padding: 15px 0;
        &:hover {
          background: #a37e48;
          color: #fff;
        }
      }
    }
    div:hover ul {
      opacity: 1;
      visibility: visible;
    }
  }
  .login {
    font-size: 16px;
    font-weight: bold;
    margin-left: 30px;
  }
}
@media screen and (max-width: 1600px) {
  .NavBar {
    padding: 0 5%;
  }
}
@media screen and (max-width: 1280px) {
  .seat {
    height: 60px;
  }
  .NavBar {
    height: 60px;
    padding: 0 25px;
    .logo {
      height: 46px;
    }
    .right .menu .cate{
      margin-top: -20px;
    }
  }
  .phone {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    .el-icon-menu {
      font-size: 26px;
    }
  }
  .mobile {
    padding: 20px;
    .search {
      display: flex;
      align-items: center;
      i {
        font-size: 24px;
        color: #969696;
      }
      margin-bottom: 40px;
    }
    .icons {
      display: flex;
      align-items: center;
      justify-content: space-around;
      & > a {
        display: block;
      }
      i {
        font-size: 26px;
        display: block;
      }
      div {
        position: relative;
      }
      ul {
        position: absolute;
        background: #fff;
        width: 100px;
        box-shadow: 0px 0px 13px 0px rgba(171, 171, 171, 0.22);
        text-align: center;
        left: 50%;
        border-radius: 4px;
        overflow: hidden;
        top: 100%;
        transform: translateX(-50%) translateY(20px);
        transition: all 0.3s;
        opacity: 0;
        visibility: hidden;
        color: #000;
        a,
        div {
          display: block;
          padding: 15px 0;
          &:hover {
            background: #a37e48;
            color: #fff;
          }
        }
      }
      div:hover ul {
        opacity: 1;
        visibility: visible;
      }
    }
    .login {
      font-size: 16px;
      font-weight: bold;
      margin-left: 30px;
    }
  }
  :deep .el-drawer {
    width: 70% !important;
  }
}
</style>
<style>
@media screen and (max-width: 768px) {
  .el-message-box {
    width: 90% !important;
  }
}
</style>
