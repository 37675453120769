<template>
  <div id="app">
    <navbar></navbar>
    <div class="container">
      <router-view />
      <div
        class="backtop pointer"
        :class="{ visible: scrollPosition > 800 }"
        @click="backtop"
      >
        <div class="icon">
          <i class="el-icon-arrow-up"></i>
        </div>
        TOP
      </div>
      <footerView></footerView>
    </div>
  </div>
</template>
<script>
import navbar from "@/components/navbar.vue";
import footerView from "@/components/footerView.vue";
export default {
  components: { navbar, footerView },
  data() {
    return {
      scrollPosition: "",
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrollPosition = window.scrollY;
    },
    backtop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
<style lang="scss" scope>
body,
html {
  overflow-x: hidden;
  line-height: 1.6;
  font-size: 14px;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
a {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}
.backtop {
  color: #6b532e;
  font-size: 16px;
  position: fixed;
  right: 50px;
  bottom: 30px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  z-index: 100;
  .icon {
    border-radius: 50%;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    border: 2px solid #6b532e;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.visible {
    opacity: 1;
    visibility: visible;
  }
}
.swiper-slide {
  text-align: center;
}
ul li {
  list-style: none;
}
img {
  max-width: 100%;
  max-height: 100%;
}
.imgDiv {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    transition: all 0.3s;
    display: block;
  }
  img:hover {
    transform: scale(1.05);
  }
}
.pointer {
  cursor: pointer;
}
.main {
  min-height: calc(100vh - 260px);
}
.el-image {
  width: 100%;
  height: 100%;
}
.swiper-container {
  height: 100%;
}
.wrap {
  padding: 0 13%;
}
@media screen and (max-width: 1600px) {
  .wrap {
    padding: 0 5%;
  }
}
@media screen and (max-width: 1024px) {
  .backtop {
    right: 23px;
  }
}
@media screen and (max-width: 768px) {
  body {
    font-size: 14px;
  }
}
</style>
