<template>
  <div class="footerView">
    <ul>
      <li>联系电话：{{ site.tel }}</li>
      <li>服务在线时间：{{ site.work_time }}</li>
      <li>地址：{{ site.address }}</li>
      <li>{{ site.copyright }} <a href="https://beian.miit.gov.cn/" target="_blank">{{ site.beian }}</a> {{ site.email }}</li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "footerView",
  data() {
    return {};
  },
  computed: {
    ...mapState(["site"]),
  },
};
</script>

<style scoped lang="scss">
.footerView {
  background: #000;
  font-size: 14px;
  color: #909090;
  padding: 0 25%;
  height: 140px;
  display: flex;
  align-items: center;
  ul {
    display: flex;
    padding: 0 30px;
    flex-wrap: wrap;
    li {
      width: 50%;
      padding: 3px;
    }
  }
}
@media screen and (max-width: 1440px) {
  .footerView {
    padding: 20px 5%;
  }
}
@media screen and (max-width: 768px) {
  .footerView {
    height: auto;
  }
  .footerView ul {
    padding: 0;
    li {
      width: 100%;
    }
  }
}
</style>
