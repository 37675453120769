import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "index",
  },
  {
    path: "/index",
    name: "index",
    component: () => import("@/views/index.vue"),
  },
  {
    path: "/product",
    name: "product",
    component: () => import("@/views/product/list.vue"),
  },
  {
    path: "/productDetail",
    name: "productDetail",
    component: () => import("@/views/product/detail.vue"),
  },
  {
    path: "/after_sales",
    name: "after_sales",
    component: () => import("@/views/service.vue"),
  },
  {
    path: "/map",
    name: "map",
    component: () => import("@/views/map.vue"),
  },
  {
    path: "/about_us",
    name: "about_us",
    component: () => import("@/views/aboutus.vue"),
  },
  {
    path: "/join_us",
    name: "join_us",
    component: () => import("@/views/joinus.vue"),
  },
  {
    path: "/message",
    name: "message",
    component: () => import("@/views/message.vue"),
  },
  {
    path: "/collection",
    name: "collection",
    component: () => import("@/views/collection.vue"),
  },
  {
    path: "/order",
    name: "order",
    component: () => import("@/views/order.vue"),
  },
  {
    path: "/addOrder",
    name: "addOrder",
    component: () => import("@/views/addOrder.vue"),
  },
  {
    path: "/orderDetail",
    name: "orderDetail",
    component: () => import("@/views/orderDetail.vue"),
  },
  {
    path: "/user",
    name: "user",
    component: () => import("@/views/user.vue"),
  },
  {
    path: "/download",
    name: "download",
    component: () => import("@/views/download.vue"),
  },
  {
    path: "/project",
    name: "project",
    component: () => import("@/views/project/list.vue"),
  },
  {
    path: "/projectDetail",
    name: "projectDetail",
    component: () => import("@/views/project/detail.vue"),
  },
];
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
export default router;
