import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import BaiduMap from "vue-baidu-map";
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(BaiduMap,{
  ak:'WhamwoeBzskqR9x8JY5oNS5strDDTmA7',
})

// import "swiper/dist/css/swiper.min.css";
// import "swiper/dist/js/swiper.min";

import { $get, $post, $upload } from "@/utils/request";
Vue.prototype.$get = $get;
Vue.prototype.$post = $post;
Vue.prototype.$upload = $upload;
Vue.directive('removeAriaHidden', {
  bind(el, binding) {
    let ariaEls = el.querySelectorAll('.el-radio__original');
    ariaEls.forEach((item) => {
      item.removeAttribute('aria-hidden');
    });
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
